import { render, staticRenderFns } from "./ResponsibleGamings.vue?vue&type=template&id=3d523eb2&"
import script from "./ResponsibleGamings.vue?vue&type=script&lang=js&"
export * from "./ResponsibleGamings.vue?vue&type=script&lang=js&"
import style0 from "./ResponsibleGamings.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports