<template>
<!-- eslint-disable max-len -->
<!--  <section class="Page ResponsibleGamings">-->
<!--    <h2 class="Title Title&#45;&#45;type-h2 Page-Title ResponsibleGamings-Title">-->
<!--      Responsible <span class="Colored">Gaming</span>-->
<!--    </h2>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        We encourage you to play responsibly-->
<!--      </h3>-->
<!--      <p>-->
<!--        Gambling is a form of entertainment, but it should be enjoyed responsibly. It´s not a way to earn a quick buck. You should always avoid chasing losses, never play with money that you can’t afford to lose, and never gamble for money that should go towards rent, bills or other expenses. You should check your activity regularly in the Balance page to keep on top of your money spent.-->
<!--      </p>-->
<!--      <p>-->
<!--        If you start to feel uncomfortable in any way due to your gambling, you should take a break from playing or even consider self-exclusion.-->
<!--      </p>-->
<!--      <p>-->
<!--        If you have any questions or concerns, please contact the Customer Support staff who are available 24/7 and they will help you.-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Responsible gaming and protection measures-->
<!--      </h3>-->
<!--      <p>-->
<!--        It’s important that our customers enjoy themselves in the casino, but we also understand that for some players, gaming can become a problem.-->
<!--      </p>-->
<!--      <p>-->
<!--        We pride ourselves of being fair, transparent and socially responsible and want to protect our players from playing irresponsibly and safeguard you, so you can have a playful, yet responsible gaming experience. This-->
<!--        is why we have the ability for you to set deposit limits or entire play limitations, these tools can help you keep control of your gambling and can be found in the My Account section of the casino.-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Cooling-off, temporary suspension and self-exclusion-->
<!--      </h3>-->
<!--      <p>-->
<!--        If you need to take a break from playing, and don’t trust yourself to stay away, then use our Player Limitation options which can be found on the Player Limitation page of ‘My Account’.-->
<!--      </p>-->
<!--      <p>-->
<!--        There are a range Player Limitations you can choose from:-->
<!--      </p>-->
<!--      <ul>-->
<!--        <li>-->
<!--          You can choose Cooling-Off, which you can choose from the following time frames, 24 hours, 48 hours or 7 days.-->
<!--        </li>-->
<!--        <li>-->
<!--          You can choose Temporary Suspension, which you can choose from the following time frames, 30 days,-->
<!--        60 days or 90 days.-->
<!--        </li>-->
<!--        <li>-->
<!--          You can choose Self-Exclusion, which gives you a period of 180 days, which you can increase by contacting support after you make the self-exclusion.-->
<!--        </li>-->
<!--      </ul>-->
<!--      <p>-->
<!--        During any Self-Exclusion you will not be able to gamble or deposit, nor will you receive any marketing material (it can take up to 24 hours to remove you from all marketing databases).-->
<!--      </p>-->
<!--      <p>-->
<!--        Please note that any Self-Exclusion set will also apply to any other account you have on our license.-->
<!--      </p>-->
<!--      <p>-->
<!--        To Self-Exclude login <a href="Javascript:openLogin({'dl':'selfex'});">here</a> now.-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Implementation of deposit limits-->
<!--      </h3>-->
<!--      <p>-->
<!--        During the process of creating an account on our Malta license you will have the option to restrict your daily, weekly or monthly deposit amount to any amount you want.-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Time limit control-->
<!--      </h3>-->
<!--      <p>-->
<!--        We have a feature available that will automatically log you out of the casino after a certain amount of time. If you would like this feature enabled please contact the support department and let them know the number of minutes you would like to be logged out.-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Reality Check-->
<!--      </h3>-->
<!--      <p>-->
<!--        You have the option to contact the support department to ask them to add a Reality Check message to your account and even tell them how many minutes there should be between each Reality Check message.-->
<!--      </p>-->
<!--      <p>-->
<!--        When you have this enabled, a message will appear across your game screen each time the number of minutes you choose passes.-->
<!--      </p>-->
<!--      <p>-->
<!--        The message will let you know how long you have been playing for, your total bets and your total wins. From this message you will have the opportunity to press one of the following buttons, Continue, which will close the message and the game will stay open, or Logout which will close game and will log you out.-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Self-Assessment-->
<!--      </h3>-->
<!--      <p>-->
<!--        A self-assessment test is just as it sounds… a test where you can check if your gambling is starting to become an issue for you. By doing a self-assessment test, you can easily understand if gambling is becoming an issue for you and act before it gets out of hand.-->
<!--      </p>-->
<!--      <p>-->
<!--        If you’re concerned about your gaming or just want to control it, you can <a href="https://son-email-direct.com/?skin=vw&amp;language=EN&amp;name=self_test">click here</a> and take the Self-Assessment Test-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Keeping a watchful eye-->
<!--      </h3>-->
<!--      <p>-->
<!--        If we notice signs that suggest you may have a gambling problem, we’ll contact you regarding our Responsible Gaming policy and discuss applying deposit limits or other restrictions.-->
<!--      </p>-->
<!--      <p>-->
<!--        You can also view the information at the below websites which may be able to provide support and advice in overcoming problems with gaming:-->
<!--      </p>-->
<!--      <a href="http://www.gamblersanonymous.org/ga/index.php" target="_blank">Gamblers Anonymous</a> <br>-->
<!--      <a href="http://www.ncpgambling.org/" target="_blank">National Council on Problem Gambling</a> <br>-->
<!--      <a href="http://www.gamcare.org.uk/" target="_blank">GamCare</a> <br>-->
<!--      <a href="http://igcouncil.org/about-us/" target="_blank">Helping Hand/IGC</a> <br>-->
<!--      <a href="http://www.gamblingtherapy.org/?ReferrerID=311" target="_blank">Gambling Therapy Helpline</a>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        We say NO to Underage Gambling-->
<!--      </h3>-->
<!--      <p>-->
<!--        We do not allow players under the age of 18 to gamble. This is stated in our <a href="https://son-email-direct.com/?skin=vw&amp;language=EN&amp;name=tnc">Terms and Conditions</a>.-->
<!--      </p>-->
<!--      <p>-->
<!--        We use both automated and manual processes in order to verify the age of the customer registering the account and any player under the age of 18 who registers an account will have their account closed immediately.-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Prevent Underage Gambling-->
<!--      </h3>-->
<!--      <p>-->
<!--        We recommend that parents install filtering programs on their computer to block certain software and websites. This will allow you to control the programs your children view and use.-->
<!--      </p>-->
<!--      <p>-->
<!--        Our recommended filtering programs are: <a href="https://www.cyberpatrol.com/" target="_blank">Cyber Patrol</a>, <a href="https://www.gamblock.com/index.html" target="_blank">GamBlock</a> and <a href="https://www.netnanny.com/" target="_blank">Net Nanny</a>.-->
<!--      </p>-->
<!--      <p>-->
<!--        We also advise taking the following precautions:-->
<!--      </p>-->
<!--      <ul>-->
<!--        <li>-->
<!--          Children should not be left unattended in front of a computer.-->
<!--        </li>-->
<!--        <li>-->
<!--          When they reach the correct age, your children should be educated as to what gambling is and how it can affect them if it is not controlled.-->
<!--        </li>-->
<!--        <li>-->
<!--          Due to the emotion that can be emitted whilst gambling, do not allow children to watch or hear you while you are playing any of our games.-->
<!--        </li>-->
<!--        <li>-->
<!--          If any children have access to your computer, we recommend you do not use the check-box for remembering your password.-->
<!--        </li>-->
<!--        <li>-->
<!--          Do not allow children to access your credit card or any other payment information.-->
<!--        </li>-->
<!--        <li>-->
<!--          A different profile that only contains appropriate software and website access can be created on your computer for children. Parents playing our games can then use a different profile.-->
<!--        </li>-->
<!--        <li>-->
<!--          Parents should educate themselves on the dangers of underage gambling and act accordingly.-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div class="Page-Section ResponsibleGamings-Section">-->
<!--      <h3>-->
<!--        Unauthorised account access: How to prevent it and How to spot it-->
<!--      </h3>-->
<!--      <p>-->
<!--        There's a lot to enjoy here at your favourite online casino, and while you can rest assured we do everything we can to keep your gaming experience here a safe one, there are a few things you can do to make it even safer.-->
<!--      </p>-->
<!--      <p>-->
<!--        Here are a few simple but effective ways you can prevent unauthorised access to your account:-->
<!--      </p>-->
<!--      <ul>-->
<!--        <li>-->
<!--          Never tell anyone your username or password-->
<!--        </li>-->
<!--        <li>-->
<!--          Leave the "Remember Password" box unchecked-->
<!--        </li>-->
<!--        <li>-->
<!--          Always make sure you've logged out when you've finished playing-->
<!--        </li>-->
<!--        <li>-->
<!--          Never tell anyone your email username or password-->
<!--        </li>-->
<!--        <li>-->
<!--          Leave the "Remember Password" box for your email account unchecked-->
<!--        </li>-->
<!--        <li>-->
<!--          Always make sure you've logged out of your email account when finished-->
<!--        </li>-->
<!--        <li>-->
<!--          Be extra vigilant on the above when using a shared computer-->
<!--        </li>-->
<!--      </ul>-->
<!--      <p>-->
<!--        Here are a few things to look out for if you think someone has accessed your account:-->
<!--      </p>-->
<!--      <ul>-->
<!--        <li>-->
<!--          Bankroll: make a note of it when logging in and out and report any discrepancies-->
<!--        </li>-->
<!--        <li>-->
<!--          Game history: keep track of the games you've played (you can get a log of games played each session by contacting support)-->
<!--        </li>-->
<!--        <li>-->
<!--          Cashier: make sure all transactions have been made by you-->
<!--        </li>-->
<!--        <li>-->
<!--          Receipts: look out for transaction receipts in your email inbox when you know you haven't been playing-->
<!--        </li>-->
<!--        <li>-->
<!--          If you suspect your account has been accessed by someone else, get in touch with support immediately.-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--  </section>-->

  <section class="Page ResponsibleGamings">
    <template v-if="isLoading">
      <Loader />
    </template>
    <div v-else-if="isError">
      Sorry, we have some problems, data could not be loaded.
    </div>
    <div v-else v-html="pageContent"></div>
  </section>
</template>

<script>
import getContent from '@/mixins/getContent';

export default {
  name: 'ResponsibleGamings',
  mixins: [getContent],
  data() {
    return {
      pageType: 'responsible_gaming',
    };
  },
  metaInfo() {
    return {
      title: this.$t('metaInfo.responsibleGaming.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.responsibleGaming.description'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.ResponsibleGamings {
  &-Title, h2 {
    margin-bottom: 30px;
    color: var(--color-main1);

    @media (min-width: $screen-m) {
      margin-bottom: 40px;
    }

    @media (min-width: $screen-l) {
      margin-bottom: 50px;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 58px;
    }
  }

  &-Section {
    margin-bottom: 10px;
  }
}
</style>
